.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1677ff;
    color: #ffffff;
    padding: 0.5rem 1.5rem;
    flex-wrap: wrap; 
}

.top-bar-left {
    display: flex;
    align-items: center;

    .logo {
        height: 40px;
        margin-right: 0.5rem;
    }
}

.top-bar-title {
    flex-grow: 1;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    padding-right: 40px
}

.top-bar-right-icon {
    display: flex;
    align-items: center;
    padding-right:40px;
    
}

.home-icon {
    background: none;
    border: none;
    color: white;
    margin-left: -40px;
    margin-right: 30px
}
.home-icon:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    color: inherit !important;
  }

.top-bar-right {
    display: flex;
    align-items: center;
    

    a {
        color: #ffffff;
        text-decoration: none;
        font-size: 1rem;
        padding-right: 30px;
        
        &:hover {
            text-decoration: underline;
        }

        /* Style for email icon */
        .anticon {
            font-size: 1.5rem;
            color: white;
        }
    }
}



/* Media Query for mobile responsiveness */
@media (max-width: 768px) {
    .top-bar {
        padding: 0.5rem 1rem;
    }

    .top-bar-left .logo {
        height: 30px;
    }

    .top-bar-title {
        font-size: 1.2rem;
        padding-right: 0px;
    }

}
