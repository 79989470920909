// Landing.scss

.landing-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .top-bar {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
        background-color: #2B6BE4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }

    .content {
        margin-top: 60px; /* Adjust to the height of the header */
        padding: 1rem;
        flex: 1;
        background-color: #f9f9f9;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px; /* Uniform spacing between child elements */

        .learn-more-banner {
            width: 60%;
            max-width: 900px;
            margin: 0 auto; /* Remove individual margins */
            margin-top: 1rem;
            padding: 10px 20px;
            background-color: #e6f7ff; /* Light blue background */
            border: 1px solid #91d5ff; /* Border color */
            border-radius: 4px;
            text-align: center;
            cursor: pointer;

            a {
                font-weight: 500;
                color: #1890ff;
            }

            a:hover {
                color: #40a9ff;
            }
        }

        .search-bar-wrapper {
            width: 60%; /* Make it full width of the parent */
            max-width: 900px; /* Maintain maximum width */
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px; /* Adjust as needed */
        }

        .search-bar-container {
            flex: 1; /* Allow the search bar to grow and fill the space */
        }

        .filters-wrapper {
            width: 60%;
            max-width: 900px;
            display: flex;
            justify-content: space-between;
            gap: 10px; /* Consistent spacing */
        }

        /* New Styles for CarCards Wrapper */
        .car-cards-wrapper {
            width: 60%;
            max-width: 1200px; /* Adjust as needed */
            margin: 20px auto; /* Center horizontally with top and bottom margins */
            padding: 0 20px; /* Optional: Add some padding on the sides */
            
            /* Optional: Add responsive behavior */
            @media (max-width: 1280px) {
                max-width: 90%;
            }

            @media (max-width: 768px) {
                max-width: 100%;
                padding: 0 10px;
            }
        }

        .loading-spinner, .error-message {
            /* Ensure these elements are also centered */
            width: 100%;
            max-width: 1200px;
            margin: 20px auto;
            padding: 0 20px;
        }
    }
}
