.image-carousel {
    position: relative;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    height: 400px;
    overflow: hidden;
    cursor: pointer;

    &.mobile {
        width: 95%;
        height: 300px;
        margin: 0 auto; 
        margin-top: 10px;
        cursor: default;
        &:active,
        &:focus {
            background: transparent;
        }
        .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            border: none;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: default;
            z-index: 2;
    
            &.left-arrow {
                left: 5px;
            }
    
            &.right-arrow {
                right: 5px;
            }
        }
        
    }


    &.fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.9);
        z-index: 1000;
        cursor: default;

        .car-image {
            max-width: 90%;
            max-height: 90%;
            border-radius: 0;
        }

        .arrow {
            &.fullscreen-arrow {
                background-color: rgba(255, 255, 255, 0.5);
                color: black;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }

    .car-image {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        min-width: 20%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        opacity: 0;
        z-index: 0;
        object-fit: cover;
        object-position: center;
        &.active {
            opacity: 1;
            z-index: 1;
        }

        &.slide-in-left {
            transform: translateX(-100%);
            animation: slideInLeft 0.3s forwards;
        }

        &.slide-in-right {
            transform: translateX(100%);
            animation: slideInRight 0.3s forwards;
        }

        &.slide-out-left {
            transform: translateX(0%);
            animation: slideOutLeft 0.3s forwards;
        }

        &.slide-out-right {
            transform: translateX(0%);
            animation: slideOutRight 0.3s forwards;
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;

        &:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }

        &.left-arrow {
            left: 10px;
        }

        &.right-arrow {
            right: 10px;
        }
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}
