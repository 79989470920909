.vehicle-details-table {
    display: flex;
    gap: 40px;
    margin: 20px auto;
    width: fit-content; // This will make it expand to fit content
    min-width: 800px; // Minimum width
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    &.mobile {
        padding: 0 10px;
        margin: 0px auto;
        margin-top: 20px;
        border-radius: 0%;

        .table-column {
            .detail-row {
                span {
                    font-size: 1rem !important;
                    padding-left: 5px;
                    word-break: break-word !important;
                    white-space: normal !important;
                    max-width: 100%; 

             }
                strong {
                    min-width: 130px;
                }
            }
        }
    }
    .table-column {
        flex: 1;
        position: relative;
        
        // Add vertical divider for first column
        &:first-child::after {
            content: '';
            position: absolute;
            top: 0;
            right: -20px; // Half of the gap
            width: 1px;
            height: 100%;
            background-color: #ddd;
        }
        
        .detail-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0;
            border-bottom: 1px solid #ddd;

            &:last-child {
                border-bottom: none;
            }

            strong {
                color: #222;
                font-size: 1rem;
                font-weight: 600;
                min-width: 150px;
            }

            span {
                color: #555;
                font-size: 1rem;
                flex-grow: 1;
                text-align: left;
                padding-left: 20px;
                
            }
        }
    }
}

.table-reserve {
    padding-left: 10px !important;
    background-color: #f8e474;
    font-weight: bold;
    border-radius: 10px;
    display: inline-block;
    padding: 2px 10px;
    margin-bottom: 5px;

}