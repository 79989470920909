.similar-cars-section {
    margin-top: 2rem;
    margin-left: 1rem;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: calc(100% - 3rem);
    &.mobile {
        margin: 2rem auto;
        padding: 10px;
    }
    .section-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        gap: 10px;
        cursor: pointer;
        h2 {
            margin: 0;
        }
        &:hover {
        opacity: 0.8;
    }
        .drawer-toggle {
            margin-left: 15px;
            cursor: pointer;
            font-size: 1.2rem;
            
            &:hover {
                opacity: 0.8;
            }
        }
    }

    .averages-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 20px 0;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 8px;
    
        .average-stat {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            strong {
                font-size: 0.9rem;
                color: #666;
                margin-bottom: 5px;
            }
            
            span {
                font-size: 1.2rem;
                font-weight: bold;
                color: #333;
            }
        }
    
        .divider {
            width: 1px;
            height: 40px;
            background-color: #ddd;
        }
    }

    .similar-cars-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 1rem;
        &.mobile {
            grid-template-columns: repeat(2, 1fr);
            gap: 0.5rem;
        }
    }
    
    .similar-car-card {
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.2s ease;
    
        &:hover {
            transform: translateY(-2px);
        }
    
        .similar-car-image {
            width: 100%;
            height: 200px;
            object-fit: cover;
            background-color: #f5f5f5; // Light gray background for empty spaces
            display: flex;
            align-items: center;
            justify-content: center;
            aspect-ratio: 16/9;
            @media (max-width: 768px) {
                height: 130px; // Adjusted height for mobile
            }
        }
        
        .similar-car-info {
            padding: 0.5rem;
            
            .price {
                font-weight: bold;
                font-size: 1.1rem;
                margin-bottom: 0.2rem;
            }
            
            .mileage, .location, .bids {
                font-size: 0.9rem;
                color: #666;
                margin-bottom: 0.1rem;
            }
            .name {
                font-weight: bold;
                font-size: .8rem;
                margin-bottom: 0.3rem;
                display: -webkit-box;
                -webkit-line-clamp: 3; // Limit to two lines
                line-clamp: 3; // Standard property
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }
            .tags-row {
                margin-bottom: 0.2rem;  // Slightly more space after tags
            }
        }
    }
    

}

