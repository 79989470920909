.car-info-widget {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    &.mobile {
        gap: 0;
        margin-top: 0;

        .controls-row {
            padding: 0 10px;
        }
    }

    .car-name {
        font-size: 1.8rem;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;

    }

    .mobile-info-row {

        .info-item {
            text-align: center;
            padding: 5px;

            &.clickable {
                cursor: pointer;

                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }

        .repair-info-item {
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }

    .bid-info-row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        width: 275px;
        margin: 0 auto;

        .info-item {
            flex: 1;
            padding: 15px;
            text-align: center;
            border: 1px solid #ddd;
            border-radius: 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            background-color: #f9f9f9;

            &.clickable {
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }

        .info-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
            gap: 40px;

            strong {
                margin-bottom: 0;
            }
        }

        .info-row-divider {
            height: 1px;
            background-color: #ddd;
            margin: 5px 0;
        }


        .negative {
            color: #e74c3c;
        }

        .current-bid {
            color: #2ecc71;
        }

    }

    strong {
        display: block;
        font-size: 1rem;
        margin-bottom: 8px;
        color: #333;
    }

    span {
        font-size: 1.2rem;
        font-weight: bold;
        color: #555;
        text-decoration: none;
    }

    .controls-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .back-button {
            cursor: pointer;
            font-size: 1.2rem;
            font-weight: 500;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}