.car-table {
    .ant-table {
        background: white;
    }

    .ant-btn-primary {
        background-color: #1890ff;
        border-color: #1890ff;
    }

    .table-name-container {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .table-image {
        max-width: 150px; 
        max-height: 150px;
        margin-right: 10px;
        border-radius: 4px; 
    }

    .table-name {
        font-weight: 500;
    }

    @media (max-width: 768px) {
        .ant-table {
            overflow-x: auto;
        }
    }

    .table-title-container {
        text-align: center;
      }
      
      .table-title {
        white-space: nowrap; 
        font-size: 14px;
        color: #000;
        margin-bottom: 4px;
      }
      
      .table-subtitle {
        white-space: nowrap; 
        font-size: 12px;
        color: #666;
      }
      
      .table-bid-container {
        text-align: center;
      }
      
      .table-bid {
        display: block;
        font-size: 16px;
        margin-bottom: 5px
      }
      
      .table-divider {
        border-bottom: 1px solid #000;
        margin: 4px 0;
      }
      
      .table-avg {
        display: block;
        font-size: 14px;
        color: #666;
        
      }

      .table-reserve {
        font-size: 10px;
        background-color: #f8e474; 
        color: black; 
        font-weight: bold;
        border-radius: 10px; 
        display: inline-block;
        padding: 2px 10px;
        margin-bottom: 5px
    }
}
